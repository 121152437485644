<template>
    <div>
        <zw-select-group v-model="inputVal"
                         name="new_value"
                         :label-prefix="labelPrefix"
                         :options="{
                                                    0: $t('common.button.no'),
                                                    1: $t('common.button.yes'),
                                                 }"
        ></zw-select-group>
    </div>
</template>

<script>
export default {
    props: {
        'value': {},
        'labelPrefix': {},
    },
    methods: {
    },
    mounted() {
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>